import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/layout-02";
import Footer from "@layout/footer/layout-01";
import PageHeader from "@containers/page-header/layout-02";
import ContactArea from "@containers/contact/layout-02";
import { Container } from "react-bootstrap";

const SecureAdaptive = ({ pageContext, location, data }) => {
    const content = normalizedData(data?.page?.content || []);
    const globalContent = normalizedData(data?.allGeneral.nodes || []);

    return (
        <Layout location={location}>
            <Seo titleTemplate="TEQT" title="SecureAdaptive"
                description="We have successfully completed $40,000,000 worth of digital marketing projects for more than 5,000 clients in more than 120 countries." />
            <Header
                data={{
                    ...globalContent["header"],
                    ...globalContent["menu"],
                    socials: data.site.siteMetadata.socials,
                }}
            />
            <main className="site-wrapper-reveal">
                <PageHeader
                    pageContext={pageContext}
                    location={location}
                    title="IT outsourcing company"
                />
                <div className="service-container">
                    <Container className="mb-5">
                        <p className="mt-5">With today’s changed working environment and hybrid working methods, usage of different device types has become common, and this has added more risks.</p>
                        <p className="mt-3">Digital workplace is compromised through Ransomware, malware, phishing, lack of data loss prevention, obsolete patches & virus signatures, lack of appropriate access management, and slow incident management.</p>
                        <p className="mt-2">Users with malicious intent are well equipped with different ways and tools to exploit the environment and create security breach situations.</p>
                        <p className="mt-2">Workplace security covers aspects of various methods, technologies, and processes to help protect the confidentiality, integrity, and availability of computer systems, networks, and data, against cyber-attacks or unauthorized access.</p>
                        <p className="mt-2">TEQT SAFE offering for workplace security helps in delivering optimized security with right enablement.</p>
                        <p className="mt-2">The SAFE platform entails protecting end-user devices from unauthorized access, protecting the endpoints from malicious attacks, providing faster response to security incidents, and creating an environment of trust.</p>
                        <p className="mt-2">SAFE framework and platform is built on the “prevention first” approach with multilayered security, faster recovery, and unified compliance and management to deliver a zero-trust architecture to our customers.</p>



                        <h6>Features of SAFE platform include:</h6>
                        <ul className="circle-list">
                            <li className="mt-2">Proactive threat management</li>
                            <li className="mt-2">Zero-day attack protection</li>
                            <li className="mt-2">Advanced security features</li>
                            <li className="mt-2">Reduction of human errors</li>
                            <li className="mt-2">Minimization of attacks & data loss</li>
                            <li className="mt-2">Regulatory compliance</li>
                        </ul>
                        <p className="mt-2">Based on NIST framework, SAFE integrates protection technologies at each layer and collects information from a breadth of sources to help eliminate distributed management and administration. The framework helps in protecting business users with the right amount of role-based security and right degree of enablement for each role.</p>
                        <p className="mt-2">Secure endpoints powered by real-time security intelligence provide better threat prevention with a centralized view of endpoint and related data security.</p>


                    </Container>
                </div>
                <ContactArea data={content["contact-section"]} />
            </main>
            <Footer data={{ ...data.site.siteMetadata }} />
        </Layout>
    );
};

export const query = graphql`
    query SecureAdaptivePageQuery {
        allGeneral {
            nodes {
                section
                ...HeaderTwo
            }
        }
        site {
            ...Site
        }
        page(title: { eq: "it-solutions" }, pageType: { eq: "innerpage" }) {
            content {
                ...PageContent
            }
        }
        allItSolution {
            nodes {
                ...ItSolutionThree
            }
        }
    }
`;

SecureAdaptive.propTypes = {
    pageContext: PropTypes.shape({}),
    location: PropTypes.shape({}),
    data: PropTypes.shape({
        allGeneral: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        site: PropTypes.shape({
            siteMetadata: PropTypes.shape({
                contact: PropTypes.shape({}),
            }),
        }),
        page: PropTypes.shape({
            content: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        allItSolution: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
};

export default SecureAdaptive;
